import React                   from 'react';
import styled                  from '@emotion/styled';
import { Container, Row, Col } from 'react-grid-system'

import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import { Button }        from '@interness/web-core/src/components/elements/Button/Button'
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';

const dispalyItems = [
  {
    title: 'Uhren',
    subtitle: 'Wir schaffen Zufriedenheit',
    descr: 'Zeit ist eine knappe Ressource geworden. Sie vergeht, ohne dass wir sie anhalten könnten. </br> Dabei gibt es so viele unvergessliche Augenblicke...',
    image: 'display/watches.jpg',
    path: '/watches'
  },
  {
    title: 'Schmuck',
    subtitle: 'Indiviuell und persönlich',
    descr: 'Schmuck ist Inspiration und Leidenschaft. Aber auch die Natürlichkeit und Authentizität unserer Schmuckkollektionen sind uns besonders wichtig.',
    image: 'display/jewelry.jpg',
    path: '/jewelry'
  },
  {
    title: 'Trauringe',
    subtitle: 'Ringe für die Ewigkeit',
    descr: 'Ein Trauring ist ein Symbol für die Liebe und soll auch im Alltag die Erinnerung an den schönsten Moment im Leben immer wieder neu erstrahlen lassen. Wenn wir von Liebe sprechen...',
    image: 'display/wedding-rings.jpg',
    path: '/wedding_rings'
  },
  {
    title: 'Optik',
    subtitle: 'Durchblick',
    descr: 'Blicken Sie gelassen in die Zukunft. In unserem Meisterbetrieb beraten wir Sie zur perfekten Sehhilfe oder Sonnenbrille. Modisch aktuell, scharf und klar und mit dem besonderen persönlichen Stil.',
    image: 'display/optic.jpg',
    path: 'https://optik-sauer.de/'
  }
];

const Display = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DisplayItem = styled.div`
  width: 22%;
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 960px) {
    width: 48%;
  }
  @media (max-width: 640px) {
    width: 100%;
  }
  > div {
    padding: 10px 0 0 0;
    margin-bottom: 20px;
  }
  img {
    margin-bottom: 0;
  }
  h4 {
    margin: 10px 0 5px 0;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }
  h5 {
    font-size: 1.1rem;
    font-weight: 300;
    text-align: left;
  }
  p {
    text-align: justify;
    font-size: 0.9rem;
  }
  a {
    position: absolute;
    bottom: -42px;
  }
`;

const List = styled.ul`
  margin-left: 0;
  li {
    margin: 0;
  }
`;

function IndexPage(props) {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Sauer</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Willkommen bei Juwelier Sauer, dem familiengeführten Juweliergeschäft der Familie Herrmann im Herzen von Meschede. Unser Juwelierhaus steht für eine einzigartige Kombination aus Tradition, handwerklicher Exzellenz und modernem Design. Wir bieten Ihnen eine exklusive Auswahl an Uhren, Schmuck, Trauringen, Eheringen und Antragsringen, die jeden Ihrer besonderen Momente unvergesslich machen.</p>
            <p>Bei Juwelier Sauer, wo die Familie Herrmann mit Leidenschaft und Hingabe für Feinheit und Qualität steht, finden Sie eine beeindruckende Kollektion, die von zeitloser Eleganz bis hin zu modernen Trends reicht. Jedes Stück in unserem Sortiment wurde sorgfältig ausgewählt, um Ihre individuellen Stilvorstellungen und Bedürfnisse zu erfüllen. Unsere maßgefertigten Trauringe und Antragsringe sind dabei ein wahres Symbol der Liebe und Hingabe.</p>
            <p>Unser Fachgeschäft bietet nicht nur exklusive Produkte, sondern auch umfassende Dienstleistungen wie professionelle Reparaturen und präzise Gravuren. Unser erfahrenes Team setzt sich dafür ein, dass jeder Besuch bei uns ein besonderes Erlebnis ist. Wir nehmen uns Zeit für eine persönliche und detaillierte Beratung, um sicherzustellen, dass Sie genau das finden, wonach Sie suchen.</p>
            <p>Besuchen Sie Juwelier Sauer in Meschede und erleben Sie den außergewöhnlichen Service und die exquisite Auswahl, die unsere Familie Herrmann stolz präsentiert. Wir sind hier, um Ihnen dabei zu helfen, das perfekte Schmuckstück oder die ideale Uhr zu finden – ein Stück, das Ihre Geschichte erzählt und Ihre Persönlichkeit widerspiegelt. Lassen Sie sich von unserer Leidenschaft für Schönheit und Präzision begeistern.</p>
            <p>Bis bald in Meschede, Ihre Familie Herrmann mit Team</p>

          </span>
        </section>
        <LiveAnnouncements/>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unser virtuelles Schaufenster</Heading>
          <Display>
            {dispalyItems.map(item => (
              <DisplayItem key={item.title}>
                <div>
                  <img src={item.image} alt={item.title}/>
                  <h4>{item.title}</h4>
                  <h5>{item.subtitle}</h5>
                  <p dangerouslySetInnerHTML={{ __html: item.descr }}/>
                </div>
                <Button as={Link} to={item.path}>Mehr erfahren...</Button>
              </DisplayItem>
            ))}
          </Display>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>Kompletter Service ist für unser Team selbstverständlich. Darum haben wir einen Uhrmacher und Goldschmied an der Hand, die Ihren Schmuck und Ihre Uhren reparieren, umarbeiten oder individuell für Sie anfertigen. So erfüllen wir Ihre Wünsche schnell, sicher und mit
            persönlichem Ansprechpartner.</p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h3>Armbanduhren</h3>
                  <List>
                    <li>mechanische Uhren / Handaufzug</li>
                    <li>Quarzarmbanduhren</li>
                    <li>Automatikuhren</li>
                    <li>Batteriewechsel</li>
                    <li>Komplette Revisionen</li>
                    <li>Taschenuhren</li>
                    <li>Glasersatz</li>
                    <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                    <li>Bandanpassung</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Goldschmiedereparaturen</h3>
                  <List>
                    <li>Goldschmiedereparaturen aller Art</li>
                    <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                    <li>Ringgrößen ändern</li>
                    <li>Perlenketten neu fassen oder knoten</li>
                    <li>Trauringumarbeitung</li>
                    <li>Neuanfertigungen</li>
                    <li>Schmuck reinigen und aufarbeiten</li>
                    <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h3>Großuhren</h3>
                  <List>
                    <li>Heim – und Tischuhren, Jahresuhren</li>
                    <li>Standuhren</li>
                    <li>Regulatoren</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Großuhrenservice</h3>
                  <p>Wussten Sie schon, dass wir Ihre große, schwere Standuhr auch bei Ihnen zu Hause abholen ? Fragen
                    Sie uns nach unserem Abhol–, Bring- sowie Montageservice.</p>
                </Col>
              </Row>
            </Container>
          </MoreExpander>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;
